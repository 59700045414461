<template>
  <b-card title="My Applications 🙌">
    <b-table
      :items="items"
      :fields="fields"
      striped
      responsive
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Request : </strong>{{ row.item.request }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Type : </strong>{{ row.item.type }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Cost : </strong>{{ row.item.Cost }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Created By : </strong>{{ row.item.Created_by }}
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

       <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
   </b-card>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge,
} from 'bootstrap-vue'
import store from '@/store/index'
import $ from 'jquery'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      fields: [{key:'short_code', label:'Request'}, {key:'sender_type', label:'Type'},{ key: 'status', label: 'Status' }, 'client_name'],
      items: [
        
      ],
      status: [{
        11: 'Pending', 4: 'Active', 12: 'Rejected'
      },
      {
        11: 'light-warning', 4: 'light-success', 12: 'light-danger'
      }],
    }
  },
  mounted(){
    this.getAllApplications()
  },
  methods: {
    getAllApplications(){
      let vm  = this
      $.get({
                url: store.state.rootUrl + 'account/v1/view/sender_ids',
                type: "get",
                async: true,
                data: {
                    shortCode:"",
                    offset:"",
                    sort:"",
                    export:"",
                    limit:"",
                    start:"",
                    end:"",
                    clientId:vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.items = []
                    } else {
                        let result = response.data.data;
                        vm.items = result
                        console.log("Items are here "+JSON.stringify(vm.items))

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.items = []
                }
            });
    }
  },
  filters: {
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        shortcodeStatus(value) {
            if (value == 11) {
                return "Pending"
            } else if (value == 13) {
                return "Active"
            } else {
                return "Disabled"
            }
        },
        shortCodeStatusColor(value) {
            if (value == 11) {
                return "light-warning"
            } else if (value == 4) {
                return "light-success"
            } else {
                return "light-danger"
            }
        }
    }
}
</script>
 